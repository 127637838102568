import backend from '../utils/backendAPI';
import { FETCH_NODE_LIST, SELECT_NODE } from './actionType';

const fetchNodeList = () => {
  return async (dispatch) => {
    const response = await backend.GET('/meshubs');

    dispatch({ type: FETCH_NODE_LIST, payload: response });
  };
};

const selectNode = (node) => {
  return { type: SELECT_NODE, payload: node };
};

export { fetchNodeList, selectNode };
