import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { fetchNodeList } from '../../actions/nodes';
import PropTypes from 'prop-types';

import MapTable from '../../components/MapTable';
import Navbar from '../../components/Navbar';

const zoomThreshold = 10;

const Home = ({ fetchNodeList }) => {
  useEffect(() => {
    fetchNodeList();
  }, []);

  return (
    <div className="home">
      <Navbar />
      <MapTable />
    </div>
  );
};

Home.propTypes = {
  fetchNodeList: PropTypes.func.isRequired,
};

export default connect(null, {
  fetchNodeList,
})(Home);
