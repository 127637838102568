import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import styles from './index.module.scss';

const MapTable = () => {
  const { fields, nodeList, selectedNode } = useSelector((state) => state.nodes);

  const dayjsFormatColumn = (rowData, field) => {
    return <span>{dayjs(rowData[field]).format('YYYY/MM/DD HH:mm')}</span>;
  };

  return (
    <>
      <div className={styles.resizeController}></div>
      <div className={styles.map_table}>
        <DataTable
          value={nodeList}
          className="p-datatable-gridlines"
          rowHover
          autoLayout
          selection={selectedNode}
          selectionMode="single"
        >
          {fields.map((field, index) => {
            if (field.dataField === 'created_at' || field.dataField === 'updated_at') {
              return (
                <Column
                  key={index}
                  field={field.dataField}
                  header={field.displayField}
                  body={(rowData) => dayjsFormatColumn(rowData, field.dataField)}
                  sortable
                />
              );
            }

            return (
              <Column key={index} field={field.dataField} header={field.displayField} sortable />
            );
          })}
        </DataTable>
      </div>
    </>
  );
};

export default MapTable;
