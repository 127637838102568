import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { fetchServicesData } from '../../actions/service';

import { makeStyles } from '@material-ui/core/styles';
import styles from './index.module.scss';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  container: {}, // do not remove this
});

const ServiceTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fields, services } = useSelector((state) => state.service);

  useEffect(() => {
    dispatch(fetchServicesData());
  }, []);

  return (
    <div className={styles.service_table}>
      <TableContainer component={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {fields.map((title) => (
                <TableCell key={title} className="header-cell">
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service) => (
              <TableRow key={service.id}>
                {fields.map((key) => {
                  if (key === 'created_at' || key === 'updated_at') {
                    return (
                      <TableCell key={key}>
                        {dayjs(service[key]).format('YYYY/MM/DD HH:mm')}
                      </TableCell>
                    );
                  }

                  // 因為現在資料是巢狀的，之後後端會改成僅有 1 層
                  if (key === 'mode') {
                    return <TableCell key={key}>{service[key]['modeName']}</TableCell>;
                  }
                  return <TableCell key={key}>{service[key]}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ServiceTable;
