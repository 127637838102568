import { FETCH_NODE_LIST, SELECT_NODE } from '../actions/actionType';

const initialState = {
  fields: [],
  nodeList: [],
  selectedNode: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_NODE_LIST: {
      return { ...state, nodeList: payload.data, fields: payload.fields };
    }

    case SELECT_NODE: {
      return { ...state, selectedNode: payload };
    }

    default: {
      return state;
    }
  }
};
