export const FETCH_NODE_LIST = 'FETCH_NODE_LIST';
export const SELECT_NODE = 'SELECT_NODE';

export const FETCH_SERVICES_DATA = 'FETCH_SERVICES_DATA';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const CHECK_AUTH = 'CHECK_AUTH';

export const SET_SNACKBAR = 'SET_SNACKBAR';
