import React from 'react';
import { useSelector } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import Home from './views/Home';
// import Node from './views/Node';
import Service from './views/Service';
import Login from './views/Login';
import BaseSnackbar from './components/BaseSnackbar';

import styles from './app.module.scss';

const App = () => {
  const auth = useSelector((state) => state.auth);

  return (
    <div className={styles.App}>
      <Router history={history}>
        <Switch>
          <Route path="/" exact render={() => (auth.token ? <Home /> : <Login />)} />
          <Route path="/login" component={Login} />
          {/* <Route path="/node" render={() => (auth.token ? <Node /> : <Login />)} /> */}
          {/* <Route path="/service" render={() => (auth.token ? <Service /> : <Login />)} /> */}
        </Switch>
      </Router>
      <BaseSnackbar />
    </div>
  );
};

export default App;
