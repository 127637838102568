import axios from 'axios';
import store from '../store';
import history from '../history';

const backend = axios.create({
  baseURL: 'https://meshub-health.meshstream.io/api',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

backend.interceptors.request.use(
  function (config) {
    const token = store.getState().auth.token;

    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default {
  async GET(url, params) {
    try {
      const response = await backend.get(url, {
        params,
      });
      return response.data;
    } catch (error) {
      history.push('/login');
      return Promise.reject(error);
    }
  },
  async POST(url, body, config) {
    try {
      const response = await backend.post(url, body, config);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
